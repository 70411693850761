import {
  CAvatar,
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImage,
} from "@coreui/react";
import useWindowDimensions from "../components/Dimensions";
import useQuiz, { QuizProvider } from "../hooks/quiz";
import logo from "../assets/LOGO.png";
import bgLandscape from "../assets/bglandscape.jpg";
import useUser from "../hooks/user";
import { __API_URL__ } from "../CONFIG";

  const Layout = ({ children }) => {
    const { height } = useWindowDimensions();
    const user = useUser();
    const isPollApp = localStorage.getItem("app_type") === "poll";

    return (
      <QuizProvider>
        <Page>
          <div className="page-container">
          {!isPollApp && <>
            {user.isLogin && (
              <div className="settings">
                <CDropdown>
                  <CDropdownToggle color="secondary">
                    <CAvatar src="http://cdn.onlinewebfonts.com/svg/img_264157.png" />
                  </CDropdownToggle>
                  <CDropdownMenu>
                  <CDropdownItem href={localStorage.getItem("quizHash") != null ? `/${localStorage.getItem("quizHash")}`: '/'}>
                      Home
                    </CDropdownItem>
                  <CDropdownItem href="/history">
                      View History
                    </CDropdownItem>
                  <CDropdownItem href="/profile">
                      Edit Profile
                    </CDropdownItem>
                    <CDropdownItem onClick={() => user.actions.logout()}>
                      Logout
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>
            )}
            </>}

            <div className="card-header">
              <BrandLogo />
            </div>

            {children}
          </div>
        </Page>
      </QuizProvider>
    );
  };

export default Layout;

export const BrandLogo = () => {
  const { quiz } = useQuiz();
  return (
    <div className="brand">
      <CImage
        fluid
        src={quiz?.settings?.business_logo_url}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = logo;
        }}
        width="100px"
      />
    </div>
  );
};

export const Page = ({ children }) => {
  const { height } = useWindowDimensions();
  const { quiz } = useQuiz();
  return (
    <div
      className="page"
      style={{
        height: height,
        backgroundImage: quiz?.settings?.background_image_url
          ? `{quiz?.settings?.background_image_url}`
          : bgLandscape,
      }}
    >
      {children}
    </div>
  );
};
