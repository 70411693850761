// import { CContainer } from "@coreui/react";
import Avatar from "../../components/Avatar";
import sandtimer from "../../assets/sand-timer.gif";
import effort from "../../assets/GreatEffort.png";
import dotLoader from "../../assets/dots.gif";
import Footer from "../../components/Footer";
import { __API_URL__ } from "../../CONFIG";

const SponsorPage = ({ sponsor_logo, sponsor_name }) => {
  return (
    <>
      <div className="outline-header mt-0">
        <div className="d-flex justify-content-center align-items-center p-2">
          <Avatar src={effort} height="30px" />
          <p style={{ margin: "0px", fontSize: "14px", marginLeft: "5px" }}>
            Great Effort! Ready for the next ?
          </p>
        </div>
        <Avatar src={dotLoader} height="50px" />
      </div>

      <div className="mt-3 text-center">
        <Avatar src={sandtimer} height="100px" />
      </div>

      <div className="mt-3 d-flex justify-content-center align-items-center">
        {sponsor_logo && (
          <img
          src={`${__API_URL__}${sponsor_logo}`}
          alt= "Avatar"
          className="img_mobile"
          loading="lazy"
        />
        )}
      </div>

      <Footer>Powered by {sponsor_name}</Footer>
    </>
  );
};

export default SponsorPage;
