import {
  CButton,
  CContainer,
  CFormInput,
  CCol,
  CAlert,
  CForm,
  CRow,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./style.scss";
import useUser from "../../hooks/user";
import Title from "../../components/Title";

const Profile = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");

  const [phone, setPhone] = useState("");

  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();
  const user = useUser();

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if(!user.isLogin){
      navigate(`/login`);
    }

    
    return () => {
      
      mounted.current = false;
    };
  }, []);

  const fetchUser = useCallback(() => {
    if(user != null) {
      setfirst_name(user?.state?.user?.first_name);
      setlast_name(user?.state?.user?.last_name);
      setEmail(user?.state?.user?.email);
      setPhone(user?.state?.user?.mobile)
      setUsername(user?.state?.user?.username)
    }
  })

  useEffect(() => {
    fetchUser()
    
  }, [])



  const handleSignup = (e) => {
    e.preventDefault();
      let updateData = {
        mobile: phone,
        user: {
          email:email,
          username:username,
          first_name:first_name,
          last_name:last_name,
        },
      };
      user.actions
        .updateProfile(user?.state?.user?.viewer, updateData)
        .then((res) => {
          console.log(res, "response")
          setModal(true);
        })
        .catch((err) => {
          if (mounted.current) {
            setDisable(false);
            setError(err.response.data);
            setErrorVisible(true);
          }
        });
  };
  // const {viewer_details} = user.state.user
  console.log(user.state.user)
  return (
    <CContainer style={{ textAlign: "center", padding: "0" }}>
      <Title>Your Profle</Title>

    <div className="profile-box">
    <h1>Profile</h1>
      <span>Edit your profile</span>
      <CForm onSubmit={(e) => handleSignup(e)} className="signup-form">
       
        
        <CCol className="mt-3">
          <CFormInput
            type="text"
            id="first_name"
            placeholder="First Name"
            disabled={disable}
            value={first_name}
            onChange={(e) => setfirst_name(e.target.value)}
          />
        </CCol>

        <CCol className="mt-3">
          <CFormInput
            type="text"
            id="last_name"
            placeholder="Last Name"
            disabled={disable}
            value={last_name}
            onChange={(e) => setlast_name(e.target.value)}
          />
        </CCol>

        <CCol className="mt-3">
          <CFormInput
            type="text"
            id="username"
            placeholder="User Name"
            disabled={false}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </CCol>

        <CCol className="mt-3">
          <CFormInput
            type="email"
            id="email"
            placeholder="Email"
            disabled={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </CCol>

        

        <CCol className="mt-3">
          <CFormInput
            type="text"
            id="phone"
            placeholder="+12-345-6789"
            disabled={disable}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </CCol>

        

        <CRow>
          <CCol>
            <CButton
              className="mt-3 mb-3"
              color="warning"
              type="submit"
              disabled={disable}
              style={{ borderRadius: "1rem" }}
            >
              Update Profile
            </CButton>
          </CCol>
        </CRow>

        <CAlert
          className="mt-3"
          color="danger"
          // dismissible
          visible={errorVisible}
          onClose={() => setErrorVisible(false)}
        >
          {error}
        </CAlert>

        <CModal
          alignment="center"
          visible={modal}
          onClose={() => {
            setModal(false);
          }}
        >
          <CModalHeader>
            <CModalTitle>Success</CModalTitle>
          </CModalHeader>
          <CModalBody>Your Profile have been Successfully Updated!!.</CModalBody>

          <CModalFooter>
            <CButton
              color="warning"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      </CForm>

    </div>

     
    </CContainer>
  );
};

export default Profile;
