import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import {
  CButton,
  CContainer,
  CFormCheck,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref, onValue, off } from "firebase/database";

import { checkAttempt, editAttempt } from "../../hooks/attempt";
import Title from "../../components/Title";
import createAttempt from "../../hooks/createAttempt";
import useUser from "../../hooks/user";
import { firebaseApp, __API_URL__ } from "../../CONFIG";
import SponsorPage from "../SponsorPage";
import StartScreen from "../QuizStart/StartScreen";
import Avatar from "../../components/Avatar";

const db = getDatabase(firebaseApp);

const ManualQuiz = () => {
  const navigate = useNavigate();
  const params = useParams();
  const user = useUser();

  const [quiz, setQuiz] = useState();
  const [question, setQuestion] = useState();
  const [options, setOptions] = useState([]);
  const [test, setTest] = useState([]);

  const [answers, setAnswers] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [complete, setComplete] = useState(false);
  const [startScreen, setStartScreen] = useState(false);
  const [timer, setTimer] = useState();
  const [modal, setModal] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [error, setError] = useState("");
  const [showExitPrompt, setShowExitPrompt] = useState(false);

  const userId = user?.state?.user?.id;
  useEffect(() => {
    console.log("DETECT PAGE RELOAD", showExitPrompt)
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = ''; // Legacy method for cross browser support
      }
      return ''; // Legacy method for cross browser support
    };
  
    // check if quiz start time in local storage
    if (JSON.parse(localStorage.getItem("startTimeManual"))) {
      setStartTime(localStorage.getItem("startTimeManual"));
    } else {
      setStartTime(new Date());
      localStorage.setItem("startTimeManual", JSON.stringify(new Date()));
    }


    if (quiz) {

      

      let attemptCount = 0;
      checkAttempt({ quiz: quiz.id }).then((res) => {
        if (res.length) {
          attemptCount = res.length;
          // console.log("ATTEMPT COUNT", res.length);
          if (attemptCount >= quiz.max_attempt_count) {
            setModal(true);
          }
        }
      });
    }

    const starCountRef = ref(db, params.url + "/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setQuiz(data);
      setQuestion(data.questions_detail[data.question_no - 1]);
      const optionList = data.questions_detail[data.question_no - 1].options.split(", ")
      const formattedOptions = [];
      let currentOption = '';
      for (let i = 0; i < optionList.length; i++) {
        const option = optionList[i].trim();
        if (/^[A-Z]:/.test(option)) {
          // Add the previous option to the formattedOptions array
          if (currentOption !== '') {
            formattedOptions.push(currentOption);
            currentOption = '';
          }
        }
        currentOption += (currentOption !== '' ? ', ' : '') + option;
      }
      
      // Add the last option to the formattedOptions array
      if (currentOption !== '') {
        formattedOptions.push(currentOption);
      }
      setOptions(formattedOptions)

      setTimer(Date.now() + (data.qs_timer + data.before_qs_timer + 5) * 1000);
      // setTimer(data.qs_timer);
      setComplete(false);
      setStartScreen(true);
      if (data.status === "COMPLETED") {
        handleQuizSubmit();
      }
      returnWaitPage(data.before_qs_timer, data.time_based, data.qs_timer);
    });
    return () => off(starCountRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handling option select
  const handleOptionSelect = (e) => {
    let optionAnswer = e.target.value.split(":")[0];
    let answerObject = {
      question: question.id,
      answer: optionAnswer,
      correct: optionAnswer === question.correct_option,
    };
    setAnswers([
      ...answers.filter((answer) => answer.question !== answerObject.question),
      answerObject,
    ]);
    setAnsweredQuestions([...answeredQuestions, question.id]);
  };

  // to submit quiz
  const handleQuizSubmit = () => {
    setShowExitPrompt(false)

    // making local variables to store the data instead of using state because of multi rendering issues
    let answerQuestions = [...answeredQuestions, question.id];
    let storeAnswers = [...answers];
    let answerObject = {};

    options.forEach((option, id) => {
      if (!document.getElementById(`option-check-${id}`).checked) {
        let optionAnswer = "F";
        answerObject = {
          question: question.id,
          answer: optionAnswer,
          correct: optionAnswer === question.correct_option,
        };
      }
    });

    options.forEach((option, id) => {
      if (document.getElementById(`option-check-${id}`).checked) {
        let optionAnswer = document
          .getElementById(`option-check-${id}`)
          .value.split(":")[0];

        answerObject = {
          question: question.id,
          answer: optionAnswer,
          correct: optionAnswer === question.correct_option,
        };
      }
    });

    storeAnswers = [
      ...answers.filter((answer) => answer.question !== answerObject.question),
      answerObject,
    ];

    let score = 0;
    answers.forEach((answer) => {
      if (answer.correct) {
        score++;
      }
    });

    // submitting attempt
    const quizId = parseInt(params.id);
    try {
      createAttempt({
        userId,
        quiz: quizId,
      }).then((res) => {
        if (res ==  undefined) {
          setError("You are allocated to an attempt for each quiz")
          setModal(true);
        } else {
          editAttempt(res.id, {
            answers: storeAnswers,
            number_of_correct_answers: score,
            questions: answerQuestions,
            time_taken: new Date().getTime() - Date.parse(startTime),
          }).then((res) => {
            localStorage.removeItem("attempt");
            localStorage.removeItem("attemptStatus");
            localStorage.removeItem("attemptId");
            localStorage.removeItem("startTimeManual");
            navigate(`/${quiz.quiz_hash}/complete`);
          });
        }
      });
    } catch (error) {
      //console.log(error);
    }
  };

  const Completionist = () => {
    useEffect(() => {
      if (quiz.questions[quiz.question_no]) {
        nextQuestion();
      } else {
       // console.log("Quiz Completed");
        handleQuizSubmit();
      }
    }, []);
    return null;
  };

  const nextQuestion = () => {
    setShowExitPrompt(!showExitPrompt)
    setComplete(true);
  };

  function returnWaitPage(timer, time_based, qs_timer) {
    setTimeout(() => {
      setStartScreen(false);
      if (time_based) {
        setTimer(new Date().getTime() + qs_timer + 5 * 1000);
      }
    }, timer * 1000);
  }

  return (
    <CContainer style={{ marginTop: complete && "0px" }}>
      <Title>Quiz Start</Title>
      {quiz && (
        <>
          {!complete ? (
            <>
              {startScreen ? (
                <StartScreen quiz={quiz} question_no={quiz.question_no} />
              ) : (
                <div className="question">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {quiz.questions[quiz.question_no - 1] ? (
                      <div>
                        <h3>
                          Question {quiz.question_no}/{quiz.questions_count}
                        </h3>
                      </div>
                    ) : (
                      <div>
                        <h1 style={{ textAlign: "center" }}>
                          No Question Added
                        </h1>
                      </div>
                    )}

                    <div>
                      <Avatar
                        src={quiz?.settings?.mascot_logo_url}
                        height="70px"
                      />
                    </div>
                  </div>

                  {question && (
                    <>
                      <div
                        className="heading dark"
                        style={{ margin: "1rem 0", fontFamily: "inherit" }}
                      >
                        <h3 style={{ margin: "0", textAlign: "left" }}>
                          {question?.description}
                        </h3>
                      </div>
                      <div className="options" onChange={handleOptionSelect}>
                        {options?.map((option, i) => (
                          <CFormCheck
                            key={i}
                            inline
                            className="option"
                            type="radio"
                            name={`option${question.id}`}
                            id={`option-check-${i}`}
                            hitArea="full"
                            value={option}
                            label={option}
                          />
                        ))}
                      </div>
                      <div>
                        {quiz.questions[quiz.question_no] ? (
                          <CButton
                            className="mb-3 mt-3"
                            onClick={nextQuestion}
                            style={{
                              width: "100%",
                              backgroundColor: "#15A6E6",
                              borderColor: "#15A6E6",
                            }}
                          >
                            Submit
                          </CButton>
                        ) : (
                          <CButton
                            className="mb-3 mt-3"
                            onClick={() => {
                              handleQuizSubmit();
                            }}
                            style={{
                              width: "100%",
                              backgroundColor: "#15A6E6",
                              borderColor: "#15A6E6",
                            }}
                          >
                            Submit
                          </CButton>
                        )}
                      </div>
                    </>
                  )}
                  <div className="quiz-time">
                    <Countdown
                      date={timer}
                      zeroPadTime={2}
                      renderer={({ minutes, seconds, completed }) => {
                        if (completed) {
                          return <Completionist />;
                        } else {
                          return (
                            <div className="countdown">
                              Time left:
                              <span className="countdown-time">
                                {minutes}:{seconds}
                              </span>
                            </div>
                          );
                        }
                      }}
                    >
                      <></>
                    </Countdown>
                  </div>
                  <CModal
                    alignment="center"
                    visible={modal}
                    onClose={() => {
                      setModal(false);
                      navigate(`/${quiz.quiz_hash}/complete`);
                    }}
                  >
                    <CModalHeader>
                      <CModalTitle>Attempt limit Reached</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                     {error}
                    </CModalBody>

                    <CModalFooter>
                      <CButton
                        color="warning"
                        onClick={() => {
                          setModal(false);
                          navigate(`/${quiz.quiz_hash}/complete`);
                        }}
                      >
                        Close
                      </CButton>
                    </CModalFooter>
                  </CModal>
                </div>
              )}
            </>
          ) : (
            <SponsorPage
              sponsor_logo={quiz.sponsor_logo}
              sponsor_name={quiz.sponsor_name}
            />
          )}
        </>
      )}
    </CContainer>
  );
};

export default ManualQuiz;
