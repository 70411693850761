import { useState, useEffect, useRef } from "react";
import { CAlert, CButton, CContainer, CFormInput } from "@coreui/react";
import axios from "axios";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import codeVerify from "../../hooks/codeVerify";
import urlVerify from "../../hooks/urlVerify";
import useUser from "../../hooks/user";
import Title from "../../components/Title";
import Avatar from "../../components/Avatar";
import { __MEDIA_URL__ } from "../../CONFIG";
import useQuiz from "../../hooks/quiz";
import checkParticipant from "../../hooks/checkParticipant";

const JoinCode = () => {
  const [joinCode, setJoinCode] = useState();
  // const [quiz, setQuiz] = useState();
  const { quiz, setQuiz } = useQuiz();
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [validateToJoin, setvalidateToJoin] = useState(false)

  const navigate = useNavigate();
  const params = useParams();
  const url = params.url;
  const user = useUser();
  const mounted = useRef(false);

  console.log("quiz data", quiz)

  useEffect(() => {
    mounted.current = true;

    urlVerify({ url }).then((res) => {
      localStorage.removeItem("quizHash")
      localStorage.removeItem("joinCode")
      localStorage.removeItem("authUsed")


      setQuiz(res);
      setJoinCode(res.join_code)
      if (res.status === "STARTED"){
        if(localStorage.getItem("token") === null){
          setvalidateToJoin(false)
          //console.log("not valid")
        }
        if(localStorage.getItem("token")){
          let viewerId = user.state.user?.viewer;
          checkParticipant({ viewerId: viewerId, quizHash: res.quiz_hash }).then(
            (res) => {
              if (res != 0) {
                setvalidateToJoin(true)
                //console.log("valid")

              }
            }
          );
        }
        //console.log("Hello got you");

      }
      if (axios.isAxiosError(res)) {
        navigate("/");
      } else {
        if (mounted.current) {
        }
      }
    });

    return () => {
      mounted.current = false;
    };
  }, [navigate, url]);

  return (
    <CContainer
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "left",
        padding: "30px 0 !important",
      }}
    >
      <Title>Quiz Join</Title>

      <div className="outline-header mt-5 mb-3">
        <h1>{quiz?.name && quiz.name}</h1>
      </div>

      {quiz?.image && (
        <div className="m-3">
          <Avatar src={`${__MEDIA_URL__}${quiz.image}`} />
        </div>
      )}

     
      {quiz?.status != "STARTED" ?<form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          if (joinCode) {
            const quizHash = url;
            codeVerify({ quizHash, joinCode, }).then((res) => {
              if (axios.isAxiosError(res)) {
                setDisable(false);
                setError("Invalid Pin Code");
                setErrorVisible(true);
              } else {
                localStorage.setItem("quizHash", quizHash);
                localStorage.setItem("viewersUrl", quiz.settings.viewer_app_url);
                localStorage.setItem("authUsed", quiz.settings.social_auth_feature)
                localStorage.setItem("app_type", "quiz");


                !user.isLogin
                  ? navigate(`/login`)
                  : navigate(`/${quizHash}/waiting`);
              }
            });
          } else {
            setDisable(false);
            setError("Joining Pin can't be empty");
            setErrorVisible(true);
          }
        }}
      >
        <CFormInput
          type="text"
          className="join-code"
          placeholder="Enter Pin"
          aria-label="Joining Pin"
          value={joinCode}
          onFocus={(e) => {
            setErrorVisible(false);
            setError("");
          }}
          onChange={(e) => {
            if (e.target.value.length <= 6) {
              setJoinCode(e.target.value);
            }
            setErrorVisible(false);
            setError("");
          }}
          disabled={disable}
          required
          style={{ textAlign: "center", padding: "10px", fontSize: "20px" }}
        />

        <CButton color="warning" type="submit" style={{ height: "52px" }}>
          <svg
            width="25"
            height="30"
            viewBox="0 0 18 12"
            fill="#000"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-4.37114e-07 7L14.17 7L10.59 10.59L12 12L18 6L12 5.24537e-07L10.59 1.41L14.17 5L-6.11959e-07 5L-4.37114e-07 7Z"
              fill="black"
            />
          </svg>
        </CButton>
      </form>
      :
      <div>
             
      {validateToJoin == true ? <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          if (joinCode) {
            const quizHash = url;
            codeVerify({ quizHash, joinCode }).then((res) => {
              if (axios.isAxiosError(res)) {
                setDisable(false);
                setError("Invalid Pin Code");
                setErrorVisible(true);
              } else {
                localStorage.setItem("quizHash", quizHash);
                !user.isLogin
                  ? navigate(`/login`)
                  : navigate(`/${quizHash}/waiting`);
              }
            });
          } else {
            setDisable(false);
            setError("Joining Pin can't be empty");
            setErrorVisible(true);
          }
        }}
      >
        <CFormInput
          type="text"
          className="join-code"
          placeholder="Enter Pin"
          aria-label="Joining Pin"
          value={joinCode}
          onFocus={(e) => {
            setErrorVisible(false);
            setError("");
          }}
          onChange={(e) => {
            if (e.target.value.length <= 6) {
              setJoinCode(e.target.value);
            }
            setErrorVisible(false);
            setError("");
          }}
          disabled={disable}
          required
          style={{ textAlign: "center", padding: "10px", fontSize: "20px" }}
        />

        <CButton color="warning" type="submit" style={{ height: "52px" }}>
          <svg
            width="25"
            height="30"
            viewBox="0 0 18 12"
            fill="#000"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-4.37114e-07 7L14.17 7L10.59 10.59L12 12L18 6L12 5.24537e-07L10.59 1.41L14.17 5L-6.11959e-07 5L-4.37114e-07 7Z"
              fill="black"
            />
          </svg>
        </CButton>
      </form>
      :
      <p>Oops!! You can longer join as quiz has already started </p>
}
      </div>
}
 
      <CAlert
        className="mt-3"
        color="danger"
        visible={errorVisible}
        onClose={() => setErrorVisible(false)}
      >
        {error}
      </CAlert>
    </CContainer>
  );
};

export default JoinCode;
