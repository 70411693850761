import React, { useEffect } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import useUser from '../../hooks/user';

import './styles.scss';

const Google = () => {
  let location = useLocation();
  const url = `${location.search}/?url=${localStorage.getItem("viewersUrl")}`;
  const app_type = localStorage.getItem("app_type")

  const navigate = useNavigate();
  const quizHash = localStorage.getItem("quizHash");
  const poll_hash = localStorage.getItem("poll_hash")
  const user = useUser();

  const onGogglelogin = async () => {
    await user.actions.googleLogin(url);
    if (app_type === "poll"){
      if (poll_hash) {
        navigate(`/poll/${poll_hash}`);
      }
      else{
        navigate(`/poll`);
      }
    }
    else{
      if (quizHash ) {
        navigate(`/${quizHash}/waiting`);
      }
      else{
        navigate(`/`);
      }
    }
     
    
  }

  useEffect(() => {
  
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      onGogglelogin()
    }
  }, [location]);


  return (
   <React.Fragment>
    <div className="loading-icon-container">
      <div className="loading-icon">
        <div className="loading-icon__circle loading-icon__circle--first"></div>
        <div className="loading-icon__circle loading-icon__circle--second"></div>
        <div className="loading-icon__circle loading-icon__circle--third"></div>
        <div className="loading-icon__circle loading-icon__circle--fourth"></div>
      </div>
      <small className='text-secondary text-center mr-2'> Just a moment </small>
    </div>
   </React.Fragment>
   
  );
};

export default Google;
