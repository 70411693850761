import { CAlert, CButton, CContainer, CFormInput } from "@coreui/react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../components/Title";
import urlVerify from "../../hooks/urlVerify";

import "./style.scss";
// import Avatar from "../../components/Avatar";

const QuizUrl = () => {
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [disable, setDisable] = useState(false);

  const navigate = useNavigate();



  return (
    <CContainer
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "left",
        padding: "30px 0 !important",
      }}
    >
      <Title>Quiz Url</Title>

      {/* <Avatar height="200px" mt /> */}
      <div className="outline-header mt-0 mb-3">
        <h1>Enter Quiz Code</h1>
      </div>

      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          if (url) {
            setDisable(true);
            urlVerify({ url }).then((res) => {
              if (axios.isAxiosError(res)) {
                setDisable(false);
                setError("Invalid Code");
                setErrorVisible(true);
              } else {
                navigate(`/${url}`);
              }
            });
          } else {
            setDisable(false);
            setError("Code can't be Empty");
            setErrorVisible(true);
          }
        }}
      >
        <CFormInput
          type="text"
          id="code"
          placeholder="Enter Code here"
          value={url}
          onChange={(e) => {
            if (e.target.value.length <= 6) {
              setUrl(e.target.value);
            }
            setErrorVisible(false);
            setError("");
          }}
          required
          disabled={disable}
          style={{ textAlign: "center", padding: "10px", fontSize: "20px" }}
        />

        <CButton color="warning" type="submit" style={{height: "52px"}}>
          <svg
            width="25"
            height="30"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-4.37114e-07 7L14.17 7L10.59 10.59L12 12L18 6L12 5.24537e-07L10.59 1.41L14.17 5L-6.11959e-07 5L-4.37114e-07 7Z"
              fill="black"
            />
          </svg>
        </CButton>
      </form>
      <CAlert
        className="mt-3"
        color="danger"
        visible={errorVisible}
        onClose={() => setErrorVisible(false)}
      >
        {error}
      </CAlert>
    </CContainer>
  );
};

export default QuizUrl;
