import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CFormText,
  CRow,
} from "@coreui/react";
import axios from "axios";
import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Title from "../../components/Title";
import useUser from "../../hooks/user";
import {GOOGLE_CLIENT_ID} from "../../CONFIG"
import GoogleButton from "react-google-button";

import "./styles.scss";

const Account = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [disable, setDisable] = useState(false);
  const user = useUser();
  const navigate = useNavigate();

  const quizHash = localStorage.getItem("quizHash");
  
  const AUTH_USED = localStorage.getItem("authUsed")
  const showOptions = AUTH_USED

  const validate= () => {
    if(email.trim().length > 0 && pass.trim().length > 0){
      return false;
    }
    else{
      return true;
    }
  }
  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const redirectUri = "google";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: `${localStorage.getItem("viewersUrl")}/${redirectUri}`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);
  return (
    <CContainer className="login-page">
      <Title>Login</Title>
      <form
        className="login-container"
        onSubmit={async (e) => {
          e.preventDefault();
          if (email && pass) {
            setDisable(true);
            try {
              await user.actions.login({ email, password: pass });
              if (quizHash) {
                navigate(`/${quizHash}/waiting`);
              }
              setDisable(false);
            } catch (err) {
              if (axios.isAxiosError(err)) {
                let data = err.response.data;
                if ("detail" in data) {
                  setError(data["detail"]);
                  setErrorVisible(true);
                  setDisable(false);
                }
              }
            }
          } else {
            setError("Invalid Email or Password");
            setErrorVisible(true);
            setDisable(false);
          }
        }}
      >
        <h1 >Login</h1>
        {/* <span>Sign in to your account</span> */}
        <CRow className="mt-3">
          <CFormLabel htmlFor="email" className="col-form-label">
            Email
          </CFormLabel>
          <CCol>
            <CFormInput
              type="email"
              id="email"
              placeholder="Enter Email here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={disable}
            />
          </CCol>
        </CRow>
        <CRow className="mt-3">
          <CFormLabel htmlFor="password">Password</CFormLabel>
          <CCol>
            <CFormInput
              type="password"
              id="password"
              placeholder="Password"
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
                setError("");
                setErrorVisible(false);
              }}
              disabled={disable}
            />
          </CCol>
        </CRow>
        <CAlert className="mt-3" color="danger" visible={errorVisible}>
          {error}
        </CAlert>
        <CRow className="my-3 justify-content-end">
          <CButton
            color="link"
            style={{ telaxtDecoration: "none", width: "auto" }}
          >
            <Link
              to="/reset/password"
              style={{ color: "#2ba7dc", textDecoration: "none", fontSize: "0.9rem"}}
            >
             <i className="fa-solid fa-lock"></i> Forgot Password
            </Link>
          </CButton>
        </CRow>

        <CButton className="login-btn" type="submit"
        disabled={validate()}
        color="warning">Login</CButton>

        <span className="d-block mt-3 mb-3">
          Don't have an account?{" "}
          <Link to="/signup" style={{ textDecoration: "none" }}>
            Register
          </Link>
        </span>
          {showOptions ? <CFormText className="text-center text-secondary mb-2">OR</CFormText>: null}
       {showOptions && <GoogleButton
              onClick={openGoogleLoginPage}
              label="Sign in with Google"
              disabled={!GOOGLE_CLIENT_ID}
              className=" google-login"
            />}
      </form>
    </CContainer>
  );
};

export default Account;
