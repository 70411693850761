import React from "react";
import { Link } from "react-router-dom";
import img from "../images/success.png";
import SubmitButton from "../component/submitButton";

export default function Success() {
  return (
    <div className="bg-main min-h-screen flex justify-center items-center font-display leading-relaxed ">
      <div className=" lg:w-full text-center">
        <div className="flex flex-col justify-center items-center gap-[18px]">
          <img src={img} alt="" className=""/>
          <div className="text-center p-10">
            <p>
              <span className="text-2xl font-black">
                Well Done ! Your vote has been submitted
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
