import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import './screens/PollApp/utils/scss/main.scss';
import useUser from "./hooks/user";
import QuizJoin from "./screens/QuizJoin";
import Login from "./screens/Login/Index";
import QuizUrl from "./screens/QuizUrl";
import Signup from "./screens/Signup";
import Profile from "./screens/Profile";
import UserHistory from "./screens/UserHistory";
import Waiting from "./screens/Waiting";
import Error from "./screens/Error";
import QuizStart from "./screens/QuizStart";
import ManualQuiz from "./screens/ManualQuiz";
import QuizComplete from "./screens/QuizComplete";
import Layout from "./Layout/Layout";
import ResetPassword from "./screens/ResetPassword";
import PasswordResetConfirm from "./screens/PasswordResetConfirm";
import QuizShare from "./screens/QuizShare";
import QuizResult from "./screens/QuizResult";
import Google from "./screens/SocialAuth/index";
import { QuizProvider } from "./hooks/quiz";
import {
  Poll, 
  LoginPoll,
  RegisterPoll,
  ScanToVote,
  Vote,
  Success,
} from "./screens/PollApp/pages"

const currentRoute = window.location.pathname;

const importStyles = async () => {
  if (!currentRoute.includes('/poll/')) {
    await import("@coreui/coreui/dist/css/coreui.min.css");
    await import("bootstrap/dist/css/bootstrap.min.css");
    await import("./App.scss")
  }
};

importStyles();

function App() {
  const user = useUser();

  return (
    <Layout>
      <Routes>
        <Route exact path="/" element={<QuizUrl />} />

        <Route exact path="/poll" element={<Poll />} />
        <Route exact path="/poll/:url" element={<Poll />} />
        <Route exact path="/poll/login" element={<LoginPoll />} />
        <Route exact path="/poll/register" element={<RegisterPoll />} />
        <Route exact path="/poll/:code/view" element={<ScanToVote />} />
        <Route exact path="/poll/:url/vote/" element={<Vote />} />
        <Route exact path="/poll/success" element={<Success />} />


        <Route exact path="/:url" element={<QuizJoin />} />
        <Route path="*" element={<Navigate to={"/"} />} />
        <Route exact path="/profile" element={<Profile />} />
         <Route exact path="/history" element={<UserHistory />} />

        {!user.isLogin ? (
          <>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route
              exact
              path="/google"
              element={<Google/>}
            />
            <Route exact path="/reset/password/" element={<ResetPassword />} />
            <Route
              exact
              path="/password/reset/:token"
              element={<PasswordResetConfirm />}
            />
            <Route exact path="/:url/waiting" element={<Error />} />

          </>
        ) : (
          <>
            <Route
              exact
              path="/login"
              element={<Navigate to={`/${localStorage.quizHash}/waiting`} />}
            />
            
            <Route
              exact
              path="/signup"
              element={<Navigate to={`/${localStorage.quizHash}/waiting`} />}
            />

            <Route
              exact
              path="/google"
              element={<Google/>}
            />
            <Route exact path="/:url/waiting" element={<Waiting />} />
            <Route exact path="/:url/start/:id/" element={<QuizStart />} />
            <Route
              exact
              path="/:url/start/:id/manual/"
              element={<ManualQuiz />}
            />
            <Route exact path="/:url/complete" element={<QuizComplete />} />
            <Route exact path="/result" element={<QuizResult />} />
            <Route exact path="/:url/share" element={<QuizShare />} />

            <Route
              exact
              path="/undefined/waiting"
              element={<Navigate to="/" />}
            />
          </>
        )}
      </Routes>
    </Layout>
  );
}

export default App;
