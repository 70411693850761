import React, { useState, useCallback } from "react";
import TextField from "../component/textField";
import SubmitButton from "../component/submitButton";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {signupApi} from '../api'
import { GOOGLE_CLIENT_ID } from "../config";
import GoogleButton from "react-google-button";


export default function RegisterPoll() {
  const [active, setActive] = React.useState(false);
  const navigate = useNavigate();

  function handleActivation(e) {
    setActive(!!e.target.value);
  }
  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const redirectUri = "google";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: `${localStorage.getItem("viewersUrl")}/${redirectUri}`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);


  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState("");
  const [itemToEdit, setItemToEdit] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobileNo: "",
    username: "",
  });
  const isDisabled = () => {
    // Check if all fields are valid (you can customize the validation logic)
    return Object.values(itemToEdit).some((value) => !value);
  };

  console.log("itemToEdit", itemToEdit);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setItemToEdit({
      ...itemToEdit,
      [name]: value ?? JSON.parse(value),
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    let signupData = {
      mobile: itemToEdit.mobileNo,
      password: itemToEdit.password,
      is_viewer: true,
      is_client: false,
      user: {
        email: itemToEdit.email,
        username: itemToEdit.username,
        first_name: itemToEdit.firstName,
        last_name: itemToEdit.lastName,
      },
    };
    const res = await signupApi(signupData);
    console.log("res", res);
    if (res.status != 201) {
      setError(res.response.data);
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="bg-main min-h-screen flex justify-center items-center font-display leading-lossed ">
      <div className=" lg:w-full text-center">
        <h1 className="text-3xl font-black mb-5">Register</h1>
        <form class="max-w-md mx-auto p-2" onSubmit={handleSignup}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
            <TextField
              name="firstName"
              type="text"
              children="First Name"
              a={register("firstName", {
                required: true,
                onChange: (e) => {
                  handleOnChange(e), handleActivation(e);
                },
              })}
              active={active}
            />
            <TextField
              name="lastName"
              type="text"
              children="Last Name"
              a={register("lastName", {
                required: true,
                onChange: (e) => {
                  handleOnChange(e), handleActivation(e);
                },
              })}
              active={active}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
            <TextField
              name="username"
              type="text"
              children="User Name"
              a={register("username", {
                required: true,
                onChange: (e) => {
                  handleOnChange(e), handleActivation(e);
                },
              })}
              active={active}
            />
            <TextField
              name="phoneNumber"
              type="text"
              children="Mobile No"
              a={register("mobileNo", {
                required: true,
                onChange: (e) => {
                  handleOnChange(e), handleActivation(e);
                },
              })}
              active={active}
            />
          </div>
          <TextField
            name="email"
            type="email"
            children="Email"
            a={register("email", {
              required: true,
              onChange: (e) => {
                handleOnChange(e), handleActivation(e);
              },
            })}
            active={active}
          />
          <TextField
            name="password"
            type="password"
            children="Password"
            a={register("password", {
              required: true,
              onChange: (e) => {
                handleOnChange(e), handleActivation(e);
              },
            })}
            active={active}
          />

          {error && <small className="text-sm text-[red]">{error}</small>}
          <SubmitButton
            title="Register"
            disabled={isDisabled()}
            style={`justify-center w-full bg-button mt-5`}
          />

<p className="mt-3 mb-3">or</p>

<div className="flex flex-jc-c w-[100%]">
          <GoogleButton
            onClick={openGoogleLoginPage}
            label="Sign up with Google"
            disabled={!GOOGLE_CLIENT_ID}
            className="justify-center w-full bg-button mt-5"
          />

          </div>
        </form>

        <div className="text-center mt-4">
          <p>
            <span className="text-gray-400">Have an account? </span>{" "}
            <Link className="text-regal-blue ml-2" to="/poll/login">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
