import { CContainer } from "@coreui/react";
import Avatar from "../../components/Avatar";
import thumbsUp from "../../assets/thumbpsup.png";
import dotLoader from "../../assets/dots.gif";
import clockTimer from "../../assets/timerhand.gif";
import Footer from "../../components/Footer";
import { __API_URL__ } from "../../CONFIG";
// import { __API_URL__ } from "../../CONFIG";

const StartScreen = ({ question_no, quiz }) => {
  return (
    <CContainer>
      <div className="outline-header mt-0">
        <div className="d-flex justify-content-center align-items-center p-2">
          <Avatar src={thumbsUp} height="30px" />
          <p style={{ margin: "0px", fontSize: "14px", marginLeft: "5px" }}>
            Brace Up! Q{question_no} being served !!
          </p>
        </div>
        <Avatar src={dotLoader} height="50px" />
      </div>
      <div className="mt-3 text-center">
        <Avatar src={clockTimer} height="100px" />
      </div>

      <div className="mt-3 d-flex justify-content-center align-items-center">
        {quiz.sponsor_logo && (
          <img
            src={`${__API_URL__}${quiz.sponsor_logo}`}
            alt= "Avatar"
            className="img_mobile"
            loading="lazy"
          />
)}
      </div>

      {quiz.sponsor_name && <Footer>Powered by {quiz.sponsor_name}</Footer>}
    </CContainer>
  );
};

export default StartScreen;
