import axios from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";

import Loading from "../components/Loading";
import { __API_URL__ } from "../CONFIG"; 
import useHeaders from "./useHeaders";

const QuizContext = createContext(null);

export const QuizProvider = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState(true);
  const [quiz, setQuiz] = useState(null);
  const [questionNo, setQuestionNo] = useState(null);

  const headers = useHeaders();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const quizByHash = useCallback(
    (quizHash) => {
      setHasLoaded(false);
      return axios
        .get(`${__API_URL__}/api/v1/display-quiz/?quiz_hash=${quizHash}`, {
          headers,
        })
        .then((res) => {
          if (mounted.current) {
            if (res.data?.results[0].question_no !== questionNo) {
              setQuestionNo(res.data?.results[0].question_no);
              setQuiz(res.data?.results[0]);
            }
            setHasLoaded(true);
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log("QUIZ REQUEST CANCELLED");
          } else {
            console.log(err);
          }
        });
    },
    [headers, questionNo]
  );

  return (
    <QuizContext.Provider
      value={{
        action: {
          byHash: quizByHash,
        },
        quiz,
        questionNo,
        hasLoaded,
        setQuiz,
      }}
    >
      {hasLoaded ? children : <Loading />}
    </QuizContext.Provider>
  );
};

const useQuiz = () => useContext(QuizContext);
export default useQuiz;
