// import bear from "../../assets/bear.png";

const Avatar = ({ src, alt, width, height, mt, className }) => {
  return (
    <div className={`cavatar ${mt && "mt-3"} ${className}`}>
      <img
        src={src}
        alt={alt ? alt : "Avatar"}
        width={width ? width : ""}
        height={height ? height : ""}
        loading="lazy"
      />
    </div>
  );
};

export default Avatar;
