import { CButton, CContainer } from "@coreui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Title";

import shareIcon from "../../icons/share.png";
import { __SHARE_URL__ } from "../../CONFIG";

const QuizShare = () => {
  const params = useParams();

  const [copy, setCopy] = useState(false);

  return (
    <CContainer
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Title>Share Quiz</Title>
      <img src={shareIcon} alt="share-icon" className="mb-3" />
      <h1>Share with others</h1>
      <span>Click the button to generate your share link</span>

      <CButton
        color="light"
        className="m-3"
        size="lg"
        onClick={() => {
          navigator.clipboard.writeText(`${__SHARE_URL__}/${params.url}`);
          setCopy(true);
        }}
      >
        {!copy ? "Share" : "Copied!"}
      </CButton>
    </CContainer>
  );
};

export default QuizShare;
