import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import Loading from "../components/Loading";
import { __API_URL__ } from "../CONFIG";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const tokenSaved = localStorage.token;
  const [isLogin, setIsLogin] = useState(
    tokenSaved === undefined ? false : true
  );
  const [token, setToken] = useState(
    tokenSaved === undefined ? null : tokenSaved
  );
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const logout = useCallback(() => {
    setToken(null);
    setIsLogin(false);
    setUser(null);
    setLoading(false);
    localStorage.clear();
  }, []);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchUser = useCallback(
    (options) => {
      return new Promise((resolve, reject) => {
        const userURL = `${__API_URL__}/api/v1/accounts/user/`;
        const userRequest = axios.get(userURL, options);

        const request = [userRequest];

        axios
          .all(request)
          .then(
            axios.spread((...responses) => {
              if (mounted.current) {
                setUser(responses[0].data);
                setIsLogin(true);
                setLoading(false);
                resolve(123);
              }
            })
          )
          .catch((err) => {
            if (axios.isAxiosError(err)) {
              logout();
              resolve(123);
            }
            reject(err);
          });
      });
    },
    [logout]
  );

  const login = useCallback(
    (ld) => {
      // setLoading(true);
      return axios
        .post(`${__API_URL__}/api/v1/token/login/`, ld)
        .then(async (res) => {
          if (mounted.current) {
            setToken(res.data.access);
            localStorage.setItem("token", res.data.access);
            // setLoading(false)
            const options = {
              headers: { Authorization: `Bearer ${res.data.access}` },
            };

            await fetchUser(options);
            return;
          }
        })
        .catch((err) => {
          //setLoading(false);
          throw err;
        });
    },
    [fetchUser]
  );
  const googleLogin = (code) => {
    return axios
      .get(`${__API_URL__}/api/v1/auth/login/google/${code}`)
      .then((res) => {
        console.log("res", res)
        setUser(res.data.user)
        setToken(res.data.access)
        localStorage.setItem("token", res.data.access);
        localStorage.setItem("usertoken", res.data.access);
        localStorage.setItem("user_id", res.data.user_id);
        setIsLogin(true)
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
  

  const signup = useCallback((sd) => {
    // setLoading(true);
    return axios
      .post(`${__API_URL__}/api/v1/profiles/viewer/`, sd)
      .then((res) => {
        if (mounted.current) {
          // setLoading(false);
          return res.data;
        }
      })
      .catch((err) => {
        // setLoading(false);
        throw err;
      });
  }, []);

  const updateProfile = useCallback((id, data) => {
    // setLoading(true);
    return axios
      .put(`${__API_URL__}/api/v1/profiles/viewer/${id}/`, data)
      .then((res) => {
        if (mounted.current) {
          // setLoading(false);
          return res.data;
        }
      })
      .catch((err) => {
        // setLoading(false);
        throw err;
      });
  }, []);

  // const signupCancel = () => {
  //   return controller.abort();
  // }

  useLayoutEffect(() => {
    if (localStorage["token"] !== undefined) {
      const options = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const getUser = async () => {
        setLoading(true);
        await fetchUser(options);
        setLoading(false);
      };
      getUser();
    }
  }, [fetchUser]);

  return (
    <UserContext.Provider
      value={{
        isLogin,
        loading,
        actions: {
          login,
          signup,
          updateProfile,
          // signupCancel,
          logout,
          googleLogin,
        },
        state: {
          user,
          token,
        },
      }}
    >
      {loading ? <Loading /> : children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);
export default useUser;
