import {
  CAlert,
  CButton,
  CContainer,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Title from "../../components/Title";
import resetPasswordConfirm from "../../hooks/resetPasswordConfirm";

const PasswordResetConfirm = () => {
  const [pass, setPass] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [disable, setDisable] = useState(false);
  const params = useParams();
  console.log("params", params);
  const navigate = useNavigate();

  return (
    <CContainer>
      <Title>Create a New Password</Title>
      <h1>Create new password</h1>
      <span>Enter a password which you won't forget</span>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (pass && pass === passConfirm) {
            setDisable(true);
            resetPasswordConfirm({
              token: params.token,
              pass,
              passConfirm,
            })
              .then((res) => {
                setDisable(false);
                navigate(`/login`);
              })
              .catch((err) => {
                console.log("err", err)
                setDisable(false);
                setError(err);
                setErrorVisible(true);
              });
          } else {
            setDisable(false);
            setError("Password does not Match");
            setErrorVisible(true);
          }
        }}
      >
        <CRow>
          <CFormLabel htmlFor="password" className="col-form-label">
            Password
          </CFormLabel>
        </CRow>
        <CFormInput
          placeholder="Password"
          type="password"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          disabled={disable}
        />
        <CRow>
          <CFormLabel htmlFor="password" className="col-form-label">
            Confirm Password
          </CFormLabel>
        </CRow>
        <CFormInput
          placeholder="Confirm Password"
          type="password"
          value={passConfirm}
          onChange={(e) => {
            setPassConfirm(e.target.value);
          }}
          disabled={disable}
        />
        <CAlert
          className="mt-3"
          color="danger"
          dismissible
          visible={errorVisible}
          onClose={() => setErrorVisible(false)}
        >
          {error}
        </CAlert>
        <CButton color="primary" className="mt-3 mb-3" type="submit">
          Submit
        </CButton>
      </form>
    </CContainer>
  );
};

export default PasswordResetConfirm;
