import {
  CButton,
  CContainer,
  CFormInput,
  CCol,
  CAlert,
  CForm,
  CFormText,
  CRow,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {GOOGLE_CLIENT_ID} from "../../CONFIG"
import GoogleButton from "react-google-button";

import "./style.scss";
import useUser from "../../hooks/user";
import Title from "../../components/Title";



const Signup = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");

  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [phone, setPhone] = useState("");

  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [signupwithEmail, setsignupwithEmail] = useState(false);

  const AUTH_USED = localStorage.getItem("authUsed")
  const showOptions = AUTH_USED
  
  const navigate = useNavigate();
  const user = useUser();

  const mounted = useRef(false);


  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const redirectUri = "google";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: `${localStorage.getItem("viewersUrl")}/${redirectUri}`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  const toggleSignupwithEmail = () => {
    setsignupwithEmail(!signupwithEmail);
  };

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
  console.log("showOptions",showOptions)
  const handleSignup = (e) => {
    e.preventDefault();
    if (pass === confirmPass && pass.length >= 8) {
      setDisable(true);
      let signupData = {
        mobile: phone,
        password: pass,
        is_viewer: true,
        is_client: false,
        user: {
          email:email,
          username:username,
          first_name:first_name,
          last_name:last_name,
        },
      };
      console.log(signupData);
      user.actions
        .signup(signupData)
        .then((res) => {
          console.log(res, "response")
          setModal(true);
        })
        .catch((err) => {
          if (mounted.current) {
            setDisable(false);
            setError(err.response.data);
            setErrorVisible(true);
          }
        });
    } else {
      setError("Invalid Password - minimum 8 characters, must have a number");
      setErrorVisible(true);
    }
  };

  return (
    <CContainer style={{ textAlign: "center", padding: "0" , width:"16rem"}}>
      <Title>Signup</Title>

      <CButton
        className="btn-default mb-3"
        onClick={() => {
          navigate("/login");
        }}
      >
        <svg
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 5L3.83 5L7.41 1.41L6 -1.04907e-06L1.04907e-06 6L6 12L7.41 10.59L3.83 7L18 7L18 5Z"
            fill="#1975FF"
          />
        </svg>
      </CButton>

      <h1>Sign up</h1>
      <span>Register your account</span>

     {!signupwithEmail && showOptions ? <div>
      <CButton className="login-btn signup-btn" type="button"
        onClick={toggleSignupwithEmail}
        color="warning">Signup with Email</CButton>
          <CFormText className="text-center text-secondary mb-2">OR</CFormText>

      <GoogleButton
              onClick={openGoogleLoginPage}
              label="Sign up with Google"
              disabled={!GOOGLE_CLIENT_ID}
              className=" google-login"
            />
      </div>:

      <CForm onSubmit={(e) => handleSignup(e)} className="signup-form">
       

        <CCol className="mt-3">
          <CFormInput
            type="text"
            id="first_name"
            placeholder="First Name"
            required
            disabled={disable}
            value={first_name}
            onChange={(e) => setfirst_name(e.target.value)}
          />
        </CCol>

        <CCol className="mt-3">
          <CFormInput
            type="text"
            id="last_name"
            placeholder="Last Name"
            disabled={disable}
            value={last_name}
            onChange={(e) => setlast_name(e.target.value)}
          />
        </CCol>

        <CCol className="mt-3">
          <CFormInput
            type="email"
            id="email"
            placeholder="Email"
            required
            disabled={disable}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </CCol>

        <CCol className="mt-3">
          <CFormInput
            type="text"
            id="username"
            placeholder="Username"
            required
            disabled={disable}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </CCol>

        <CCol className="mt-3">
          <CFormInput
            type="text"
            id="phone"
            placeholder="+12-345-6789"
            disabled={disable}
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
          />
        </CCol>

        <CCol className="mt-3">
          <CFormInput
            type="password"
            id="password"
            placeholder="Password"
            disabled={disable}
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
        </CCol>
        <CCol className="mt-3">
          <CFormInput
            type="password"
            id="confirm-password"
            placeholder="Repeat Password"
            disabled={disable}
            value={confirmPass}
            onChange={(e) => setConfirmPass(e.target.value)}
          />
        </CCol>

        <CRow>
          <CCol>
            <CButton
              className="mt-3"
              color="warning"
              type="submit"
              disabled={disable}
              style={{ borderRadius: "50%" }}
            >
              <svg
                width="25"
                height="30"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-4.37114e-07 7L14.17 7L10.59 10.59L12 12L18 6L12 5.24537e-07L10.59 1.41L14.17 5L-6.11959e-07 5L-4.37114e-07 7Z"
                  fill="white"
                />
              </svg>
            </CButton>
          </CCol>
        </CRow>

        <CAlert
          className="mt-3"
          color="danger"
          // dismissible
          visible={errorVisible}
          onClose={() => setErrorVisible(false)}
        >
          {error}
        </CAlert>

        <CModal
          alignment="center"
          visible={modal}
          onClose={() => {
            setModal(false);
            navigate("/login");
          }}
        >
          <CModalHeader>
            <CModalTitle>Success</CModalTitle>
          </CModalHeader>
          <CModalBody>You have been successfully registered.</CModalBody>

          <CModalFooter>
            <CButton
              color="warning"
              onClick={() => {
                setModal(false);
                navigate("/login");
              }}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      </CForm>}
    </CContainer>
  );
};

export default Signup;
