import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CContainer } from "@coreui/react";
import { getDatabase, ref, onValue, off } from "firebase/database";

import "./style.scss";
import Announced from "./Announced";
// import WaitingToStart from "./WaitingToStart";
import { firebaseApp } from "../../CONFIG";
import Loading from "../../components/Loading";
import ResultAwaited from "./ResultAwaited";
import Ended from "./Ended";
import useUser from "../../hooks/user";
import Activated from "./Activated";
import ResultAnnounced from "./ResultAnnounced";
import Error from "../Error";

const Waiting = () => {
  const navigate = useNavigate();
  const params = useParams();

  const user = useUser();
  let viewerId = user.state.user?.viewer;

  const [quiz, setQuiz] = useState();

  const db = getDatabase(firebaseApp);

  var STATUS = {
    announced: "ANNOUNCED",
    activated: "ACTIVATED",
    started: "STARTED",
    result_awaited: "RESULT_AWAITED",
    result_announced: "RESULT_ANNOUNCED",
    completed: "COMPLETED",
  };

  useEffect(() => {
    if (user.state.user) {
      const starCountRef = ref(db, params.url + "/");
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        setQuiz(data);
      });
      return () => off(starCountRef);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerId]);

  return (
    <>
      {quiz ? (
        <CContainer
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 0 !important",
          }}
        >
          {quiz.status === STATUS.announced && (
            <>
              {new Date(quiz.start_date_time) > new Date() ? (
                <Announced quiz={quiz} />
              ) : (
                <Announced quiz={quiz} /> 
              )}
            </>
          )}
          {quiz.status === STATUS.activated && (
            <Activated quiz={quiz} viewerId={viewerId} />
          )}
          {quiz.status === STATUS.started && (
            <>
              {localStorage.getItem("qs_no") ? (
                navigate(`/${quiz.quiz_hash}/start/${quiz.id}/`)
              ) : (
                <>
                  {localStorage.setItem("qs_no", 0)}
                  {navigate(`/${quiz.quiz_hash}/start/${quiz.id}/`)}
                </>
              )}
            </>
          )}
          {quiz.status === STATUS.result_awaited && (
            <ResultAwaited quiz={quiz} />
          )}
          {quiz.status === STATUS.result_announced && (
            <ResultAnnounced quiz={quiz} />
          )}
          {quiz.status === STATUS.completed && <Ended />}
        </CContainer>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Waiting;
