import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import img from "../images/barcode.png";
import SubmitButton from "../component/submitButton";
import { pollResults } from "../api";
import { useParams, useNavigate } from "react-router-dom";

export default function ScanToVote() {
  const [poll, setPoll] = useState([]);
  const [error, setError] = useState("");
  const { code } = useParams();

  const getResults = async (code) => {
    try {
      const res = await pollResults(code);
      console.log("res", res.status);
      if (res.status == 200) {
        setPoll(res.data);
        setError("");
      } else {
        console.log("Error fetching poll results:", res.message);
        setError(res.message);
      }
    } catch (error) {
      console.log("Error fetching poll results:", error);
      setError(error);

      // Handle the error here (e.g., show a message to the user)
    }
  };

  useEffect(() => {
    if (code) {
      getResults(code);

      // Polling every 5 seconds (adjust as needed)
      const intervalId = setInterval(() => {
        getResults(code);
      }, 3000);

      // Cleanup function to clear the interval when component unmounts
      return () => clearInterval(intervalId);
    }
  }, [code]);

  console.log("getResults", poll);

  return (
    <div className="bg-main min-h-screen flex justify-center items-center font-display leading-relaxed ">
      <div className=" lg:w-full text-center">
        <div className="flex flex-col justify-center items-center gap-[18px]">
          <div className="text-center p-5">
            <p>
              <span className="text-2xl font-black">
                Scan the QR Code to cast your Vote
              </span>
            </p>
          </div>
          <img
            src={`https://res.cloudinary.com/dk2il6f0i/${poll?.qr_image}`}
            width="200"
            height="200"
            alt=""
            className=""
          />
          <div className="text-center p-5">
            <p>
              <span className="text-2xl font-black">{poll.name}</span>
            </p>
          </div>
          {poll?.show_options &&
            poll?.choices &&
            poll?.choices.length > 0 &&
            poll?.choices?.map((choice, index) => {
              const percentage = (
                (choice.votes / poll.total_votes) *
                100
              ).toFixed(2);
              return (
                <div
                  key={index}
                  className="flex flex-jc-sb lg:w-1/2 h-10 bg-blue-200 rounded-lg text-black w-[90%] "
                >
                  <div
                    className="relative h-10 pt-2 pl-2 bg-blue-600 rounded-lg md:dark:bg-blue-500 bg-== my-auto "
                    style={{ width: `${choice.votes ? percentage : 0}%` }}
                  >
                    
                  </div>
                  <small
                    className="absolute first-letter:mt-[0.5rem] font-bold p-2 lg:pl-2 w-100
                  text-white"
                  >
                    {choice.name}{" "}
                  </small>

                  <small className="p-2 font-bold">
                    {choice.votes ? percentage : 0}%
                  </small>
                </div>
              );
            })}
          {poll && poll?.show_total_votes && (
            <p className="text-start mt-5 mb-5 ">{poll.total_votes} votes</p>
          )}

          {/* {error && <p className="text-center text-[blue] mt-5 mb-5 ">{error}</p>} */}
        </div>
      </div>
    </div>
  );
}
