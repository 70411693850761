import { CContainer } from "@coreui/react";

import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Title from "../../components/Title";
import { getDatabase, ref, onValue, off } from "firebase/database";
import { firebaseApp, __API_URL__ } from "../../CONFIG";
import Avatar from "../../components/Avatar";
import Footer from "../../components/Footer";
import greatEffort from "../../assets/GreatEffort.png";
import clap from "../../assets/clap.png";

const QuizComplete = () => {
  const [quiz, setQuiz] = useState();
  const params = useParams();
  const navigate = useNavigate();

  const db = getDatabase(firebaseApp);

  useEffect(() => {
    const starCountRef = ref(db, params.url + "/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setQuiz(data);
      if (
        data.status === "RESULT_AWAITED" ||
        data.status === "RESULT_ANNOUNCED"
      ) {
        navigate(`/${params.url}/waiting`);
      }
    });
    return () => off(starCountRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CContainer
    // style={{
    //   display: "flex",
    //   flexDirection: "column",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   padding: "30px 0 !important",
    //   width: "100%",
    // }}
    >
      <Title>Quiz Completed</Title>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="outline-header mt-0">
          <div className="d-flex justify-content-center align-items-center p-2">
            <Avatar src={greatEffort} height="50px" />
            <p style={{ margin: "0px", fontSize: "15px", marginLeft: "5px" }}>
              Well Done ! You have completed the quiz. Scores being calculated
              !!
            </p>
          </div>
        </div>

        <Avatar src={clap} height="100px" mt />

        {quiz && (
          <>
            {quiz.sponsor_logo && (
              <div className="d-flex justify-content-center align-items-center">
                <img
            src={`${__API_URL__}${quiz.sponsor_logo}`}
            alt= "Avatar"
            className="img_mobile"
            loading="lazy"
          />
              </div>
            )}
            {quiz.sponsor_name && (
              <Footer>Powered by {quiz.sponsor_name}</Footer>
            )}
          </>
        )}
      </div>
    </CContainer>
  );
};

export default QuizComplete;
