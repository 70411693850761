import { CButton, CCol, CContainer, CRow } from "@coreui/react";

import { useAttempt } from "../../hooks/attempt";
import pass from "../../icons/pass.png";
// import fail from "../../icons/fail.png";
import { useNavigate } from "react-router-dom";
import Title from "../../components/Title";

const QuizResult = () => {
  const attempt = useAttempt(localStorage.getItem("attemptId"));
  const navigate = useNavigate();
  if (attempt.hasLoaded) {
    return (
      <CContainer
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px 0 !important",
        }}
      >
        <Title>Quiz Result</Title>
        {attempt.state ? (
          <>
            <img src={pass} alt="pass-fail-icon" />
            <h1
              style={{
                textAlign: "center !important",
                fontSize: "30px",
              }}
            >
              Congratulations
            </h1>
            <span className="mb-3">You completed your Quiz</span>
            <CRow className="mb-3 full-width">
              <CCol>
                <span>Viewer</span>
                <p>{attempt.state.viewer}</p>
              </CCol>
              <CCol>
                <span>Quiz Mode</span>
                <p>{attempt.state.quiz.mode}</p>
              </CCol>
              <CCol>
                <span>Quiz Name</span>
                <p>{attempt.state.quiz.name}</p>
              </CCol>
            </CRow>
            <CRow className="mb-5">
              <CCol>
                <span>Total Questions</span>
                <p>{attempt.state.questions.length}</p>
              </CCol>
              <CCol>
                <span>Correct Answers</span>
                <p>{attempt.state.score}</p>
              </CCol>
              <CCol>
                <span>Attempt Date</span>
                <p>{new Date(attempt.state.start_date_time).toDateString()}</p>
              </CCol>
            </CRow>
            <div className="btns mt-3">
              <CButton
                className="btn-primary m-1"
                onClick={() => navigate("/")}
              >
                Continue
              </CButton>
              <CButton
                color="light m-1"
                onClick={() => navigate(`/${localStorage.quizHash}/share`)}
              >
                Share{" "}
                <svg
                  width="21"
                  height="12"
                  viewBox="0 0 21 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.59 1.41L14.17 5H0V7H14.17L10.58 10.59L12 12L18 6L12 0L10.59 1.41ZM19 0V12H21V0H19Z"
                    fill="#1A75FF"
                  />
                </svg>
              </CButton>
            </div>
          </>
        ) : (
          <h1>Result not announced</h1>
        )}
      </CContainer>
    );
  } else {
    return (
      <CContainer>
        <span>Loading...</span>
      </CContainer>
    );
  }
};

export default QuizResult;
