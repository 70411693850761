import {
  CAlert,
  CButton,
  CContainer,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../components/Title";
import resetPassword from "../../hooks/resetPassword";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [disable, setDisable] = useState(false);

  const navigate = useNavigate();
  const site_url = window.location.origin

  return (
    <CContainer>
      <Title>Reset Password</Title>
      <CButton
        className="btn-default mb-3"
        onClick={() => {
          navigate("/login");
        }}
      >
        <svg
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 5L3.83 5L7.41 1.41L6 -1.04907e-06L1.04907e-06 6L6 12L7.41 10.59L3.83 7L18 7L18 5Z"
            fill="#1975FF"
          />
        </svg>
      </CButton>
      <h1>Reset Password</h1>
      <span>Enter your registered email to get a reset password email</span>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (email) {
            setDisable(true);
            resetPassword({ email, site_url }).then((res) => {
              setDisable(false);
              setError(res.detail);
              setErrorVisible(true);
            });
          } else {
            setError("Please enter your email");
            setErrorVisible(true);
          }
        }}
      >
        <CRow>
          <CFormLabel htmlFor="email" className="col-form-label">
            Email
          </CFormLabel>
        </CRow>
        <CFormInput
          placeholder="Enter email here"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          disabled={disable}
        />

        <CAlert
          className="mt-3"
          color="primary"
          // dismissible
          visible={errorVisible}
          onClose={() => {
            setError("");
            setErrorVisible(false);
          }}
        >
          {error}
        </CAlert>

        <CButton color="warning" className="mt-2 mb-3" type="submit">
          Submit
        </CButton>
      </form>
    </CContainer>
  );
};

export default ResetPassword;
