import axios from "axios";
import { __API_URL__ } from "../CONFIG";

export default function resetPassword(email, site_url) {
  return axios
    .post(`${__API_URL__}/api/rest-auth/password/reset/`, email, site_url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
