import { useEffect } from "react";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import Avatar from "../../components/Avatar";
import Title from "../../components/Title";
import { __API_URL__ } from "../../CONFIG";

const Announced = ({ quiz }) => {
  const navigate = useNavigate();

  const Completionist = () => {
    useEffect(() => {
      navigate(`/${quiz.quiz_hash}/waiting`);
    }, []);
    return null;
  };
  return (
    <>
      <Title>Quiz Announcement</Title>
      <div className="outline-header mt-0">
        <h1 className="">{quiz.name}</h1>
      </div>
      <img
            src={`${__API_URL__}${quiz.sponsor_logo}`}
            alt= "Avatar"
            className="img_mobile"
            loading="lazy"
          />

      <Countdown
        date={quiz.start_date_time}
        zeroPadTime={2}
        renderer={({ hours, minutes, seconds, completed }) => {
          if (!completed) {
            return (
              <div className="timing primary-color mt-3">
                <h2 style={{ fontSize: "20px" }}>Time To Start : </h2>
                <div className="min-sec">
                  {hours > 0 ? (
                    <>
                      <h2>
                        {hours < 10 && "0"}
                        {hours}:{minutes < 10 && "0"}
                        {minutes}
                      </h2>
                      <span className="label">Hours Mins</span>
                    </>
                  ) : (
                    <>
                      <h2>
                        {minutes < 10 && "0"}
                        {minutes}:{seconds < 10 && "0"}
                        {seconds}
                      </h2>
                      <span className="label">Mins Secs</span>
                    </>
                  )}
                </div>
              </div>
            );
          } else {
            return <Completionist />;
          }
        }}
      >
        <></>
      </Countdown>
    </>
  );
};

export default Announced;
