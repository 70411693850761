import axios from "axios";
import { __API_URL__ } from "../CONFIG";

export default function codeVerify(code) {
  return axios
    .post(`${__API_URL__}/api/v1/quiz-code-verify/`, code)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
}
