import { CContainer, CSpinner } from "@coreui/react";

const Loading = () => {

  return (
    <CContainer
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "30px 0 !important",
      }}
    >
      <CSpinner
        color="primary"
        style={{
          transform: "scale(1.5)",
        }}
        variant="grow"
      />
      <h2>Loading...</h2>
    </CContainer>
  );
};

export default Loading;
