import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CButton,
  CContainer,
  CFormCheck,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import Countdown from "react-countdown";

import "./style.scss";
import useQuiz from "../../hooks/quiz";
import useUser from "../../hooks/user";
import createAttempt from "../../hooks/createAttempt";
import { checkAttempt, editAttempt } from "../../hooks/attempt";
import Title from "../../components/Title";
import SponsorPage from "../SponsorPage";
import StartScreen from "./StartScreen";
import Avatar from "../../components/Avatar";
import { __API_URL__ } from "../../CONFIG";
import checkParticipant from "../../hooks/checkParticipant";
import createParticipant from "../../hooks/createParticipant";

const QuizStart = () => {
  const [qsNo, setQsNo] = useState(parseInt(localStorage.getItem("qs_no")));
  const [options, setOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [timer, setTimer] = useState();
  const [qsStartDate, setQsStartDate] = useState();
  const [waitingDisplay, setWaitingDisplay] = useState(false);
  const [startScreen, setStartScreen] = useState(true);
  const [modal, setModal] = useState(false);
  const [startTime, setStartTime] = useState(null);

  const { quiz, action } = useQuiz();
  const { url } = useParams();
  const quizHash = url;
  const user = useUser();
  const params = useParams();
  const navigate = useNavigate();

  let quizId = parseInt(params.id);

  if (user.state.user) {
    var userId = user.state.user.id;
    var viewerId = user.state.user.viewer;
  } else {
    navigate(`${__API_URL__}/waiting`);
  }

  useEffect(() => {
    if (localStorage.getItem("startTimeAuto")) {
      setStartTime(localStorage.getItem("startTimeAuto"));
    } else {
      setStartTime(new Date());
      localStorage.setItem("startTimeAuto", new Date());
    }

    if (quiz === null) {
      action.byHash(quizHash);
    } else {
      checkAttempt({ quiz: quiz.id }).then((res) => {
        if (res.length) {
          if (res.length >= quiz.max_attempt_count) {
            setModal(true);
          }
        }
      });

      if (quiz.mode === "AUTO") {
        if (user.isLogin) {
          checkParticipant({
            viewerId: viewerId,
            quizHash: quiz.quiz_hash,
          }).then((res) => {
            if (res === 0) {
              createParticipant({ quiz: quiz.id, viewer: viewerId }).then(
                (res) => res
              );
            }
          });
          setOptions(quiz.questions_detail[qsNo]?.options.split(","));
          if (quiz.time_based) {
            setTimer(new Date().getTime() + quiz.qs_timer * 1000);
            setQsStartDate(new Date());
          }
          setStartScreen(true);
          returnWaitPage(quiz.before_qs_timer, quiz.time_based, quiz.qs_timer);
        }
      } else {
        navigate(`manual/`);
      }
    }
    if (localStorage.quizHash !== params.url) {
      localStorage.removeItem("attempt");
      localStorage.removeItem("attemptId");
      localStorage.removeItem("attemptStatus");
      localStorage.removeItem("startTimeAuto");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, quiz, quizHash]);

  // when selecting any option
  const handleOptionSelect = (e) => {
    let optionAnswer = e.target.value.replace(/\s+/g, "");

    let answerObject = {
      question: quiz.questions_detail[qsNo].id,
      answer: optionAnswer,
      correct: optionAnswer === quiz.questions_detail[qsNo].correct_option,
    };

    setAnswers([
      ...answers.filter((answer) => answer.question !== answerObject.question),
      answerObject,
    ]);

    setAnsweredQuestions([
      ...answeredQuestions,
      quiz.questions_detail[qsNo].id,
    ]);
  };

  // to submit quiz
  const handleQuizSubmit = () => {
    // making local variables to store the data instead of using state because of multi rendering issues
    let answerQuestions = [
      ...answeredQuestions,
      quiz.questions_detail[qsNo].id,
    ];
    let storeAnswers = [...answers];
    let answerObject = {};

    options.forEach((option, id) => {
      if (document.getElementById(`option-check-${id}`).checked) {
        let optionAnswer = option.replace(/\s+/g, "").toUpperCase();
        answerObject = {
          question: qsNo,
          answer: optionAnswer,
          correct: optionAnswer === quiz.questions[qsNo].correct_option,
        };
      }
    });

    storeAnswers = [
      ...answers.filter((answer) => answer.question !== answerObject.question),
      answerObject,
    ];

    let score = 0;
    answers.forEach((answer) => {
      if (answer.correct) {
        score++;
      }
    });

    createAttempt({
      userId,
      quiz: quizId,
    }).then((res) => {
      editAttempt(res.id, {
        answers: storeAnswers,
        number_of_correct_answers: score,
        questions: answerQuestions,
        time_taken: new Date().getTime() - startTime.getTime(),
      }).then((res) => {
        localStorage.removeItem("attempt");
        localStorage.removeItem("attemptStatus");
        localStorage.removeItem("attemptId");
        localStorage.removeItem("qs_no");
        localStorage.removeItem("startTimeAuto");
        navigate(`/${quiz.quiz_hash}/complete`);
      });
    });
  };

  const Completionist = () => {
    console.log("Quiz Completed");
    useEffect(() => {
      if (quiz.questions[qsNo + 1]) {
        nextQuestion(getQsRemainingSeconds());
      } else {
        handleQuizSubmit();
      }
    }, []);
    return null;
  };

  const nextQuestion = (qsRemainingSeconds) => {
    setOptions(quiz.questions_detail[qsNo + 1].options.split(","));
    setWaitingDisplay(true);
    setQsNo(qsNo + 1);
    localStorage.setItem("qs_no", qsNo + 1);
    if (quiz.time_based) {
      setTimer((new Date().getTime() + quiz.qs_timer) * 1000);
    }
    returnWaitPage(
      parseInt(qsRemainingSeconds) + parseInt(quiz.timer_bw_qs),
      quiz.time_based,
      quiz.qs_timer
    );
  };

  function returnWaitPage(timer, time_based, qs_timer) {
    setTimeout(() => {
      setStartScreen(false);
      setWaitingDisplay(false);
      if (time_based) {
        setTimer(new Date().getTime() + qs_timer * 1000);
        setQsStartDate(new Date());
      }
    }, timer * 1000);
  }

  function getQsRemainingSeconds() {
    return (
      quiz.qs_timer -
      (new Date().getTime() - qsStartDate.getTime()) / 1000
    ).toFixed(0);
  }

  return (
    <>
      <Title>Quiz Started</Title>
      {!waitingDisplay ? (
        <>
          {quiz && startScreen ? (
            <StartScreen question_no={qsNo + 1} quiz={quiz} />
          ) : (
            <CContainer
              style={{
                position: "relative",
              }}
            >
              {quiz && quiz.mode === "AUTO" && (
                <div className="question">
                  {quiz.questions[qsNo] ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div>
                          <h3>
                            Question {qsNo + 1}/{quiz.questions_count}
                          </h3>
                        </div>
                        {quiz.image && (
                          <div>
                            <Avatar src={"quiz.image"} height="70px" />
                          </div>
                        )}
                      </div>
                      <div
                        className="heading dark"
                        style={{ margin: "1rem 0", fontFamily: "inherit" }}
                      >
                        <h3 style={{ margin: "0", textAlign: "left" }}>
                          {quiz.questions_detail[qsNo]?.description}
                        </h3>
                      </div>
                      <div className="options" onChange={handleOptionSelect}>
                        {options?.map((option, i) => (
                          <CFormCheck
                            key={i}
                            inline
                            className="option"
                            type="radio"
                            name={`option${quiz.questions[qsNo].id}`}
                            id={`option-check-${i}`}
                            hitArea="full"
                            value={option.split(":")[0]}
                            label={option}
                          />
                        ))}
                      </div>
                      <div className="mt-3">
                        {quiz.questions[qsNo + 1] ? (
                          <CButton
                            className="mb-3"
                            onClick={() => {
                              nextQuestion(getQsRemainingSeconds());
                            }}
                            style={{ width: "100%" }}
                          >
                            Submit
                          </CButton>
                        ) : (
                          <CButton
                            className="mb-3"
                            onClick={handleQuizSubmit}
                            style={{ width: "100%" }}
                          >
                            Submit
                          </CButton>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="heading dark">
                      <h3 style={{ textAlign: "center" }}>No Question Added</h3>
                    </div>
                  )}
                  <div className="quiz-time">
                    <Countdown
                      date={timer}
                      zeroPadTime={2}
                      renderer={({ minutes, seconds, completed }) => {
                        console.log(completed);
                        if (completed) {
                          return <Completionist />;
                        } else {
                          return (
                            <div className="countdown">
                              Time left:
                              <span className="countdown-time">
                                {minutes < 10 && "0"}
                                {minutes}:{seconds < 10 && "0"}
                                {seconds}
                              </span>
                            </div>
                          );
                        }
                      }}
                    >
                      <></>
                    </Countdown>
                  </div>

                  <CModal
                    alignment="center"
                    visible={modal}
                    onClose={() => {
                      setModal(false);
                      navigate(`/${quiz.quiz_hash}/complete`);
                    }}
                  >
                    <CModalHeader>
                      <CModalTitle>Attempt limit Reached</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      You have reached the limit of attempts for this quiz.
                    </CModalBody>

                    <CModalFooter>
                      <CButton
                        color="warning"
                        onClick={() => {
                          setModal(false);
                          navigate(`/${quiz.quiz_hash}/complete`);
                        }}
                      >
                        Close
                      </CButton>
                    </CModalFooter>
                  </CModal>
                </div>
              )}
            </CContainer>
          )}
        </>
      ) : (
        <SponsorPage
          sponsor_logo={quiz.sponsor_logo}
          sponsor_name={quiz.sponsor_name}
        />
      )}
    </>
  );
};

export default QuizStart;
