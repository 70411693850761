import "./styles.scss";

const CircleProgress = ({ children, value }) => {
  return (
    <div className="circle-wrap"
    style={{
      backgroundColor:'#FFA5B6'
    }}
    >
      <div className="circle">
        <div className="mask full" style={{ transform: `rotate(${value}deg)` }}>
          <div
            className="fill"
            style={{ transform: `rotate(${value}deg)` }}
          ></div>
        </div>
        <div className="mask half">
          <div
            className="fill"
            style={{ transform: `rotate(${value}deg)` }}
          ></div>
        </div>
        <div className="inside-circle"
          style={{
            backgroundColor:'#FF90A3'
          }}
        >{children}</div>
      </div>
    </div>
  );
};

export default CircleProgress;
