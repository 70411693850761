import Avatar from "../../components/Avatar";
import Title from "../../components/Title";
import sandtimer from "../../assets/sand-timer.gif";
import Footer from "../../components/Footer";
import greatEffort from "../../assets/GreatEffort.png";
import { __API_URL__ } from "../../CONFIG";

const ResultAwaited = ({ quiz }) => {
  return (
    <>
      <Title>Waiting for result</Title>

      <div className="outline-header mt-0">
        <div className="d-flex justify-content-center align-items-center p-3">
          <Avatar src={greatEffort} height="50px" />
          <p style={{ margin: "0px", fontSize: "15px", marginLeft: "5px" }}>
            Results being announced soon !!
          </p>
        </div>
      </div>

      <div className="mt-3">
        <Avatar src={sandtimer} height="100px" />
      </div>

      {quiz?.sponsor_logo && (
        <img
        src={`${__API_URL__}${quiz?.sponsor_logo}`}
        alt= "Avatar"
        className="img_mobile"
        loading="lazy"
      />
      )}

      <Footer>Powered by {quiz?.sponsor_name}</Footer>
    </>
  );
};

export default ResultAwaited;
