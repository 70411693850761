import axios from "axios";
import { __API_URL__ } from "../CONFIG";
import getHeaders from "./getHeaders";

export default function createAttempt(values) {
  const headers = getHeaders();
  return axios
    .post(`${__API_URL__}/api/v1/attempts/`, values, {
      headers,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}
