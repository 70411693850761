import axios from "axios";
import { __API_URL__ } from "../CONFIG";

export default function urlVerify(url) {
  return axios
    .post(`${__API_URL__}/api/v1/quiz-url-verify/`, url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
