import Avatar from "../../components/Avatar";
import Title from "../../components/Title";
import byePic from "../../assets/bye.png";
import byeGif from "../../assets/bye.gif";

const Ended = () => {
  return (
    <>
      <Title>Quiz Ended</Title>
      <div className="outline-header">
        <div className="d-flex justify-content-center align-items-center p-3">
          <Avatar src={byePic} height="30px" />
          <p style={{ margin: "0px", fontSize: "15px", marginLeft: "5px" }}>
            This quiz is no longer active.
          </p>
        </div>
      </div>

      <div className="mt-5">
        <Avatar src={byeGif} height="200px" />
      </div>
    </>
  );
};

export default Ended;
