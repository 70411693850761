import Avatar from "../../components/Avatar";
import Title from "../../components/Title";
import Footer from "../../components/Footer";
import useUser from "../../hooks/user";
import { attemptByViewer } from "../../hooks/attempt";
import CircleProgress from "../../components/CircleProgress";
import React, { useEffect, useState } from "react";
import { __API_URL__ } from "../../CONFIG";
import correct from "../../assets/correct.png";
import incorrect from "../../assets/incorrect.png";

const ResultAnnounced = ({ quiz }) => {
  const user = useUser();
  let viewerId = user.state.user.viewer;

  const [attempt, setAttempt] = useState();
  const [viewAnswers, setViewAnswers] = useState(false);
  const [loading, setLoading] = useState(false);


  const get_question_description_by_id = (id) =>{
    const questions = attempt.quiz.question_list
    for (let i = 0; i < questions?.length; i++) {
      if (questions[i].id === id) {
        return questions[i].description;
      }
    }
    return "";
  }

  useEffect(() => {
    if (viewerId) {
      setLoading(true);
      attemptByViewer().then((res) => {
        const filteredData = res.filter((v) => v.quiz.id === quiz.id);
        setAttempt(filteredData[0]);
        setLoading(false);
      });
    }
  }, [viewerId]);
  console.log("attempt.question", attempt)
  return (
    <>
      <Title>Result Announced</Title>

      {attempt && !loading ? (
        <div>
          {!viewAnswers ? (
            <>
              <div className="outline-header mt-0">
                <h1
                  style={{
                    color: "black",
                  }}
                >
                  {quiz.name}
                </h1>
              </div>
              {attempt && (
                <>
                  <div
                    className="card pink-card mt-3"
                    style={{
                      backgroundColor: "#FF90A3",
                    }}
                  >
                    <div className="body-tag">
                      <CircleProgress
                        value={
                          // (attempt.number_of_correct_answers /
                          //   quiz.questions.length) *
                          180
                        }
                      >
                        {attempt.score < 0 ? 0 : attempt.score}
                      </CircleProgress>
                      <div className="text">
                        You answered {attempt.number_of_correct_answers} out of{" "}
                        {quiz.questions.length} questions correctly
                      </div>
                    </div>
                    <div
                      className="bottom-tag"
                      style={{
                        backgroundColor: "#FEB381",
                      }}
                    >
                      <div
                        className="c-icon"
                        style={{
                          backgroundColor: "#FF9B52",
                        }}
                      >
                        #{attempt.score !== 0 ? attempt.rank : "-"}
                      </div>
                      <div className="text">
                        {attempt.score !== 0 ? (
                          <h3>
                            Your rank : {attempt.rank} Out of{" "}
                            {quiz.participants?.length}{" "}
                            {quiz.participants?.length > 1
                              ? "participants"
                              : "participant"}
                          </h3>
                        ) : (
                          <h3>You were not ranked because your score was 0</h3>
                        )}
                        {attempt.score !== 0 && (
                          <p>
                            Ranked on basis of score and time taken to answer
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* view answers */}
              <div className="view-answers">
                <div
                  className="btn-answer"
                  onClick={(e) => setViewAnswers(true)}
                >
                  View Answers
                </div>
              </div>
              {quiz.image && (
                <Avatar height="100px" src={`${__API_URL__}${quiz.image}`} mt />
              )}
              {quiz.sponsor_name && (
                <Footer>Powered by {quiz.sponsor_name}</Footer>
              )}
            </>
          ) : (
            <>
              <div className="outline-header mt-0">
                <h1>{quiz.name}</h1>
              </div>
              <div
                className="outline-header mt-3"
                style={{
                  height: "200px",
                  overflowY: "scroll",
                  textAlign: "left",
                  padding: "10px",
                }}
              >
                {attempt.answers.map((answer, i) => (
                  <div className="answer" key={i}>
                    {console.log(answer)}
                    <div className="answer-icon">
                      {answer.correct ? (
                        <Avatar src={correct} width="40px" />
                      ) : (
                        <Avatar src={incorrect} width="40px" />
                      )}
                    </div>
                    <div className="answer-data">
                      <p>{answer.question_description || 
                      get_question_description_by_id(answer.question)
                      }</p>
                      <p>Correct Answer: {answer.answer}</p>
                    </div>
                  </div>
                ))}

                <hr />
                {attempt.quiz.question_list
                  .filter((quest) => !attempt.questions.includes(quest.id))
                  .map((question, i) => (
                    <div className="answer" key={i}>
                      {console.log("question", question)}
                      <div className="answer-icon">
                        <Avatar src={incorrect} width="40px" />
                      </div>
                      <div className="answer-data">
                        <h6 style={{ color: "gray", marginTop: "1rem" }}>
                          Not Answered Questions
                        </h6>
                        <p>{question.description}</p>
                        <small>{question.options.split(" , ")}</small>
                        <p>Correct Answer: {question.answer}</p>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="view-answers">
                <div
                  className="btn-answer"
                  onClick={(e) => setViewAnswers(false)}
                >
                  View Score
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="text-center">
        {!loading ? "You have no recent attempt for this quiz!" : "Loading..."}
        </div>
      )}
    </>
  );
};

export default ResultAnnounced;
