import React, { useState, useEffect} from "react";
import TextField from "../component/textField";
import SubmitButton from "../component/submitButton";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import {fetchPoll} from '../api'
import axios from 'axios';


export default function Poll () {

  const [active, setActive] = React.useState(false);
  const [error, setError] = useState("");
  const [joinCode, setJoincode] = useState("");


  console.log("joinCode", joinCode)

  const [poll, setPoll] = useState([])
  const navigate = useNavigate();


  const { url } = useParams();

  console.log("url", url);

  const handleActivation = (e) => {
    setActive(!!e.target.value);
  }
  
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [itemToEdit, setItemToEdit] = useState({
    code: "",
  });
  const getPoll = async (url) => {
    const data = await fetchPoll(url);
    console.log(data)
    if (data) {
      setPoll(data);
      setJoincode(data.join_code)
      setItemToEdit({code: data.join_code})
      localStorage.setItem("viewersUrl", data.settings.viewer_app_url);

    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setItemToEdit({
      ...itemToEdit,
      [name]: value ?? JSON.parse(value),
    });
  };

  useEffect(() => {
    // Check if user token exists in localStorage
    const userToken = localStorage.getItem('usertoken');
    
    if(url){
      localStorage.setItem('poll_hash', url);
      localStorage.setItem("app_type", "poll");
      getPoll(url);

    }

    if (!userToken) {
      navigate('/poll/login');
    }
  }, [navigate]);

  return (
    <div className="bg-main min-h-screen flex justify-center items-center font-display leading-relaxed ">
      <div className=" lg:w-full text-center">
        <h1 className="text-3xl font-black ">Enter Poll Code</h1>
        {/* <p className="py-5 text-xl">{joinCode || itemToEdit?.code}</p> */}
        <form class="max-w-md mx-auto" 
         onSubmit={(e) => {
          e.preventDefault();
          if (itemToEdit?.code) {
            fetchPoll(url).then((res) => {
              if (axios.isAxiosError(res)) {
                setError("Invalid Code");
              } else {
                if(res.join_code == itemToEdit?.code){
                  navigate(`/poll/${res.poll_hash}/vote`);
                  localStorage.setItem('poll_hash', res.poll_hash)
                  localStorage.setItem("viewersUrl", res.settings.viewer_app_url);
                  localStorage.setItem("app_type", "poll");

                }
                else{
                  setError("Invalid Code");
                }
              }
            });
          } else {
            setError("Code can't be Empty");
         
          }
        }}
        >
          
          <TextField
            name="code"
            type="text"
            children=""
            value={joinCode}
            a={register("code", {
              required: true,
              onChange: (e) => {
                handleOnChange(e), handleActivation(e) ; 
              },
            })}
            active={active}
          />

{       error && <small className="text-sm text-[red]">{error}</small>
}
        <SubmitButton
            title="Submit"
            style={`justify-center mx-auto bg-button mt-5`}
          />
        </form>
      </div>
    </div>
  );
};

