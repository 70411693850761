import Avatar from "../../components/Avatar";
import Title from "../../components/Title";

import sandTimer from "../../assets/sand-timer.gif";
import welcome from "../../assets/welcome.png";
import dotLoader from "../../assets/dots.gif";
import { useCallback,useEffect, useState } from "react";
import checkParticipant from "../../hooks/checkParticipant";
import createParticipant from "../../hooks/createParticipant";
import Footer from "../../components/Footer";
import { CButton } from "@coreui/react";
import { __API_URL__ } from "../../CONFIG";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/user";

const Activated = ({ quiz, viewerId }) => {
  const [joined, setJoined] = useState(false);
  const navigate = useNavigate();
  const user = useUser();

  // const { quiz: quizDetails } = useQuiz();
  // console.log(quiz);

  const join = useCallback(() => {
    setJoined(true);
    let { id, quiz_hash } = quiz;
    checkParticipant({ viewerId: viewerId, quizHash: quiz_hash }).then(
      (res) => {
        if (res === 0) {
          createParticipant({ quiz: id, viewer: viewerId }).then((res) => res);
        }
      }
    );
  }, [quiz, viewerId]);
  
  useEffect(() => {
    if(localStorage.getItem("token")){
      let viewerId = user.state.user?.viewer;
      let { quiz_hash } = quiz;
      checkParticipant({ viewerId: viewerId, quizHash:quiz_hash }).then(
      (res) => {
        if (res !== 0) {
          setJoined(true)
          }
            }
          );
        }
        //console.log("Hello got you");
  }, []);

  const signup = () => {
    user.actions.logout()
    localStorage.clear();
    navigate(`/signup`);
    
  }



  return (
    <>
      {joined ? (
        <>
          <Title>Waiting to more players</Title>

          <div className="outline-header">
            <div className="d-flex justify-content-center align-items-center p-2">
              <Avatar src={welcome} height="50px" />
              <p style={{ margin: "0px", fontSize: "15px", marginLeft: "5px" }}>
                Waiting for others to join!!
              </p>
            </div>
            <Avatar src={dotLoader} height="40px" />
          </div>
          <div className="m-3">
            <Avatar src={sandTimer} height="80px" />
          </div>

          <div className="">
            {quiz.sponsor_logo && (
               <img
               src={`${__API_URL__}${quiz?.sponsor_logo}`}
               alt= "Avatar"
               className="img_mobile"
               loading="lazy"
             />
            )}
          </div>

          <div
            className="members"
            style={{
              position: "absolute",
              top: "25px",
              right: "50%",
              transform: "translateX(50%)",
            }}
          >
            {quiz?.participants?.length} Joined{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-eye-fill"
              viewBox="0 0 16 16"
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
          </div>
          {quiz?.sponsor_name && <Footer>Powered by {quiz?.sponsor_name}</Footer>}
        </>
      ) : (
        <>
          <Title>Join the Quiz</Title>

          <div className="outline-header">
            <h1>{quiz.name}</h1>
          </div>

          {quiz?.sponsor_logo && (
            <img
            src={`${__API_URL__}${quiz?.sponsor_logo}`}
            alt= "Avatar"
            className="img_mobile"
            loading="lazy"
          />
          )}

         <div className="form">
            <h1 className="head">Join the quiz</h1>
            <CButton color="warning" onClick={join} style={{ height: "52px" }}>
              <svg
                width="25"
                height="30"
                viewBox="0 0 18 12"
                fill="#000"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-4.37114e-07 7L14.17 7L10.59 10.59L12 12L18 6L12 5.24537e-07L10.59 1.41L14.17 5L-6.11959e-07 5L-4.37114e-07 7Z"
                  fill="black"
                />
              </svg>
            </CButton>
          </div>

          {!viewerId && <div className="form">
            <p className="head">We see you are a staff user, You need to signup

            <p
              onClick={signup}
              style={{ color: "blue", cursor:"pointer", textDecoration: "underline", marginLeft:"0.2rem" }}
            >
               here 
            </p> with a different account to participate in a quiz.
            </p>
            
          </div>}

          {quiz?.sponsor_name && <Footer>Powered by {quiz?.sponsor_name}</Footer>}
        </>
      )}
    </>
  );
};

export default Activated;
