import React, { useState, useEffect } from "react";
import SubmitButton from "../component/submitButton";
import { VoteQestionsWithOption } from "../data/voteQuestions";
import { fetchPoll, pollVote } from "../api";
import { useParams, useNavigate } from "react-router-dom";

export default function Vote() {
  const [selected, setSelected] = useState("");
  const [poll, setPoll] = useState([]);
  const [error, setError] = useState("");
  const { url } = useParams();
  const navigate = useNavigate();

  const getPolls = async () => {
    const data = await fetchPoll(url);
    if (data) {
      setPoll(data);
    }
  };

  const handleVoteSubmit = async () => {
    const res = await pollVote(url, selected);

    if (res.status == 200) {
      navigate("/poll/success");
    } else {
      setError(res.response.data.message);
      setSelected("");
    }
  };
  const handleLogout = () => {
    // Add your logout logic here
    localStorage.removeItem("usertoken");
    navigate("/poll/login"); // Redirect to login page after logout
  };

  useEffect(() => {
    getPolls();
  }, []);

  const handleOptionClick = (id) => {
    setSelected(id);
  };

  return (
    < div className="bg-main">
        <div 
        style={{
          display:'flex',
          justifyContent:'space-between',
          padding:'1rem',
          backgroundColor:'transparent'
          


        }}>
          <div
            style={{
              color:'black',
              fontWeight:'500',
              fontSize:'1rem',
              cursor:'pointer'
            }}
          >
            LivePoll
          </div>
          <div
            onClick={handleLogout}
            style={{
              color:'black',
              fontWeight:'500',
              fontSize:'1rem',
              cursor:'pointer'
            }}
          >
            Logout
          </div>
        </div>
      <div className="min-h-screen flex flex-col justify-center items-center font-display leading-lossed ">
        <div className=" lg:w-full text-center">
          <h1 className="text-3xl font-black mb-5">{poll.name}</h1>
          {poll?.options &&
            poll?.options.length > 0 &&
            poll?.options?.map((data, index) => (
              <button
                type="button"
                onClick={() => handleOptionClick(data.id)}
                className={` ${
                  selected === data.id
                    ? "hover:bg-blue-500 bg-blue-500 text-white"
                    : ""
                } text-gray-900 w-5/6 lg:w-1/2  border focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2`}
              >
                <div className="grid grid-cols-10 gap-2">
                  <div className="text-center">{index + 1}</div>
                  <div className="col-span-9 text-left">{data.name} </div>
                </div>
              </button>
            ))}{" "}
        </div>
        {error && <small className="text-sm text-[red]">{error}!</small>}
        <button
          type="submit"
          disabled={!selected}
          onClick={handleVoteSubmit}
          className={`group relative flex  py-2 px-4 border bg-blue-500 border-transparent text-sm font-medium rounded-md text-white justify-center mx-auto mt-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
          Vote
        </button>
      </div>
    </div>
  );
}
