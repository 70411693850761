import {
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import "./style.scss";
import useUser from "../../hooks/user";
import { attemptByViewer } from "../../hooks/attempt";
import Title from "../../components/Title";

const UserHistory = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");

  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [phone, setPhone] = useState("");

  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [attempts, setAttempts] = useState([]);
  const [listAttempts, setlistAttempts] = useState([]);

  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const ITEMS_PER_PAEG = 5;

  useEffect(() => {
    const endOffset = itemOffset + ITEMS_PER_PAEG;
    setPageCount(Math.ceil(attempts.length / ITEMS_PER_PAEG));
    setlistAttempts(attempts.slice(itemOffset, endOffset));
  }, [attempts, itemOffset]);


  const handlePageClick = (event) => {
    const newOffset = (event.selected * ITEMS_PER_PAEG) % attempts.length;
    setItemOffset(newOffset);
  };

  const navigate = useNavigate();
  const user = useUser();

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if(!user.isLogin){
      navigate(`/login`);
    } 
    return () => {
      mounted.current = false;
    };
  }, []);


  useEffect(() => {
    if (user.isLogin) {
      attemptByViewer().then((res) => {
        setAttempts(res);
      });
    }
  }, [user]);

  
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function convertMsToMinutesSeconds(milliseconds) {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.round((milliseconds % 60000) / 1000);
  
    return seconds === 60
      ? `${minutes + 1}:00`
      : `${minutes}:${padTo2Digits(seconds)}`;
  }


  function formatDate(timestamp){
    var x=new Date(timestamp);
    var dd = x.getDate();
    var mm = x.getMonth()+1;
    var yy = x.getFullYear();
    var time = x.getTime();

    return dd +"/" + mm+"/" + yy ;
 }



  
  
  return (
    <CContainer style={{ textAlign: "center", padding: "0" }}>
      <Title>Your History</Title>

    <div className="user-history">
        <CTable responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">Rank</CTableHeaderCell>
              <CTableHeaderCell scope="col">Quiz</CTableHeaderCell>
              <CTableHeaderCell scope="col">TIme Taken</CTableHeaderCell>
              <CTableHeaderCell scope="col">Score</CTableHeaderCell>
              <CTableHeaderCell scope="col">Answered</CTableHeaderCell>

            </CTableRow>
          </CTableHead>
          <CTableBody>
          {listAttempts.map((attempt, index) => (
              <CTableRow color="light ">
                <CTableDataCell className="badge bg-primary mt-1 text-light" style={{padding:"0.3rem 0.5rem"}}>{attempt.rank || '--'}</CTableDataCell>
                <CTableDataCell>{attempt.quiz.name}</CTableDataCell>
                <CTableDataCell>{convertMsToMinutesSeconds(attempt.time_taken)}</CTableDataCell>
                <CTableDataCell>{attempt.score}</CTableDataCell>
                <CTableDataCell>{attempt.number_of_correct_answers}/{attempt.quiz.question_list.length} </CTableDataCell>

              </CTableRow>
          ))}
          
          </CTableBody>
         </CTable>
  
        {listAttempts && listAttempts.length > 5 && <div className="d-flex justify-content-center align-items-center">
          <ReactPaginate
            containerClassName="pagination mt-2"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
          />
         
        </div>}
        
      </div>
    

     
    </CContainer>
  );
};

export default UserHistory;
