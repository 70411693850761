import { initializeApp } from "firebase/app";

const DEBUG = false;

// SERVER URLS
const LOCAL_SERVER = "http://localhost:8000";
const PROD_SERVER = "https://api.quizzers.club";

// APP URLS
const LOCAL_APP = "http://localhost:3000";
const PROD_APP = "https://quizzers.club/";

export const GOOGLE_CLIENT_ID = "941224135259-bjcejif8p4dqud6onsk9a4m7dioh30dq.apps.googleusercontent.com"


// MEDIA
const MEDIA = "/media/";

const __SHARE_URL__ = DEBUG ? LOCAL_APP : PROD_APP;
const __API_URL__ = DEBUG ? LOCAL_SERVER : PROD_SERVER;
const __MEDIA_URL__ = DEBUG ? LOCAL_SERVER + MEDIA : PROD_SERVER + MEDIA;

// Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyB5P8QGnc7ObYoeA1FmXtD6pkLa0M5o6to",
  authDomain: "quizboard-admin.firebaseapp.com",
  databaseURL:
    "https://quizboard-admin-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "quizboard-admin",
  storageBucket: "quizboard-admin.appspot.com",
  messagingSenderId: "762558123310",
  appId: "1:762558123310:web:f78403e064d0171d6e3ebf",
  measurementId: "G-G8LNRQHW0L",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// VARIABLES
export { __API_URL__, __SHARE_URL__, __MEDIA_URL__, firebaseApp };
