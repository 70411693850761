import axios from "axios";
import { __API_URL__ } from "../CONFIG";
import getHeaders from "./getHeaders";

export default function checkParticipant({ viewerId, quizHash }) {
  const headers = getHeaders();
  return axios
    .get(
      `${__API_URL__}/api/v1/participant/?quiz__quiz_hash=${quizHash}${
        viewerId && `&viewer__id=${viewerId}`
      }`,
      {
        headers,
      }
    )
    .then((res) => {
      return res.data.length;
    })
    .catch((err) => {
      return err;
    });
}
