import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { __API_URL__ } from "../CONFIG";
import getHeaders from "./getHeaders";
import useHeaders from "./useHeaders";

export const useAttempt = (id) => {
  const [state, setState] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  const headers = useHeaders();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    axios
      .get(`${__API_URL__}/api/v1/attempts/${id}/`, {
        headers,
      })
      .then((res) => {
        if (mounted.current) {
          setState(res.data);
          setHasLoaded(true);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.log(err);
        }
      });
    return () => {
      mounted.current = false;
    };
  }, [id, headers]);

  return { state, hasLoaded };
};

export const editAttempt = (id, data) => {
  const headers = getHeaders();
  return axios
    .patch(`${__API_URL__}/api/v1/attempts/${id}/`, data, { headers })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const checkAttempt = ({ quiz }) => {
  const headers = getHeaders();
  return axios
    .get(`${__API_URL__}/api/v1/attempts/check/?quiz=${quiz}`, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};

export const attemptByViewer = () => {
  const headers = getHeaders();
  return axios
    .get(`${__API_URL__}/api/v1/attempts/`, {
      headers,
    })
    .then((res) => res.data.results)
    .catch((err) => err);
};
